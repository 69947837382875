<template>
  <div>
    <loading-derek v-if="loaderDerek" />
    <component :is="menuLayoutDerek"></component>
    <main
      class="base-container"
      :style="
        footerLayoutDerek == 'footer-simple'
          ? 'min-height: calc(100vh - 142px); margin-top: 0'
          : ''
      "
    >
      <router-view></router-view>
    </main>
    <modal-login />
    <modal-register />
    <modal-news-latter-done />
    <modal-cupo />
    <modal-sold-out />
    <component :is="footerLayoutDerek"></component>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'

import MenuBase from '@/components/Menu/MenuBase.vue'
// import MenuCart from '@/components/Menu/MenuCart.vue'
import MenuSimple from '@/components/Menu/MenuSimple.vue'
import NotMenuFooter from '@/components/Menu/NotMenuFooter.vue'
import FooterDerek from '@/components/Footer/FooterDerek.vue'
import ModalLogin from '@/components/Templates/ModalLogin.vue'
import FooterSimple from '@/components/Footer/FooterSimple.vue'
import LoadingDerek from '@/components/Templates/LoadingDerek.vue'
import ModalRegister from '@/components/Templates/ModalRegister.vue'
import ModalNewsLatterDone from '@/components/Templates/ModalNewsLatterDone.vue'
import ModalCupo from '@/components/Templates/ModalCupo.vue'
import ModalSoldOut from '@/components/Bag/ModalSoldOut'

export default {
  name: 'App',
  components: {
    FooterDerek,
    MenuBase,
    // MenuCart,
    MenuSimple,
    FooterSimple,
    LoadingDerek,
    ModalLogin,
    ModalRegister,
    ModalNewsLatterDone,
    ModalCupo,
    NotMenuFooter,
    ModalSoldOut,
  },
  computed: {
    ...mapState('auth', ['dataUser']),
    ...mapState('cart', ['productsCart']),
    ...mapState('layouts', [
      'menuDerek',
      'menuLayoutDerek',
      'footerLayoutDerek',
    ]),
    ...mapState(['loaderDerek', 'showModal423']),
  },
  watch: {
    $route() {
      if (window.localStorage.getItem('accessToken')) {
        let data = new FormData()
        data.append(
          'Authorization',
          'Bearer ' + window.localStorage.getItem('accessToken'),
        )
        this.axios({
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization:
              'Bearer ' + window.localStorage.getItem('accessToken'),
          },
          url: '/auth/validate_token',
          data: data,
        }).catch(async () => {
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenCart')
          this.$store.state.cart.productsCart = null
          window.localStorage.removeItem('tokenFavorite')
          this.$store.state.user.dataUser = null
          this.$store.state.user.directions = null
          this.$store.state.products.productWhislist = []
          this.$store.state.products.productWhislist = []
        })
      }
    },
    productsCart() {
      if (this.productsCart && this.productsCart.find(p => p.status == 0)) {
        this.$bvModal.show('modal-products-sold-out')
      }
    },
    showModal423() {
      if (this.showModal423) {
        this.$bvModal.show('modal-products-sold-out')
      }
    },
  },
  created() {
    this.$analytics.fbq.init('156260184938401', {})
    if (window.localStorage.getItem('tokenFavorite')) {
      this.getProductsFavorites()
    }
  },
  async mounted() {
    this.$store.state.innerWidth = window.innerWidth
    this.changeMenu({ menu: 'menu-base' })
    this.changeFooter({ footer: 'footer-derek' })
    if (window.localStorage.getItem('accessToken')) {
      let data = new FormData()
      data.append(
        'Authorization',
        'Bearer ' + window.localStorage.getItem('accessToken'),
      )
      await this.axios({
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
        },
        url: '/auth/validate_token',
        data: data,
      }).catch(async () => {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('tokenCart')
        this.$store.state.cart.productsCart = null
        window.localStorage.removeItem('tokenFavorite')
        this.$store.state.user.dataUser = null
        this.$store.state.user.directions = null
        this.$store.state.products.productWhislist = []
        this.$store.state.products.productWhislist = []
      })
    }
    this.getTopbar()
    this.getMenuDerek()
    if (window.localStorage.getItem('accessToken')) {
      this.getProductsFavorites()
      this.getProfile()
    }
    if (window.localStorage.getItem('tokenCart')) {
      this.$store.state.loaderDerek = true
      this.getProducts().then(() => {
        this.$store.state.loaderDerek = false
      })
    }
    sendFBQEvent(this, 'PageView', {})
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions('layouts', [
      'getMenuDerek',
      'changeMenu',
      'changeFooter',
      'getTopbar',
    ]),
    ...mapActions('products', ['getProductsFavorites']),
    ...mapActions('user', ['getProfile']),
    ...mapActions('cart', ['getProducts']),
    onResize() {
      this.$store.state.innerWidth = window.innerWidth
    },
  },
}
</script>
